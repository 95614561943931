import React from 'react'
import Head from 'next/head'

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Not Found</title>
      </Head>
      <div className="absolute inset-0 flex items-center justify-center">
        <div>
          <div className="flex flex-col justify-center text-center max-w-75">
            <div className="mb-2">
              <h2 className="m-0 text-base font-medium text-gray-500">
                Payment link not found
              </h2>
            </div>
            <div className="mb-3">
              <p className="m-0 mb-2 font-normal leading-snug text-gray-400 text-13">
                Please check the payment link shared to you or contact the
                merchant that sent you the link.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Custom404
